import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, 
    private router: Router) { }

  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (state.url === '/pages/login') {
      return true;
    }

    const currentUser = this.authService.CurrentUserValue;
    const token = localStorage.getItem('currentUser'); // check token
    if (currentUser || token) {
        return true;
    }

    this.router.navigateByUrl("pages/login");

    return false;
  }
}
