<!--Login Page Starts
<section id="login">
  <div class="row auth-height full-height-vh m-0">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="card overflow-hidden">
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row m-0">
              <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                <img src="assets/img/gallery/login.png" alt="" class="img-fluid" width="300" height="230">
              </div>
              <div class="col-lg-6 col-12 px-4 py-3">
                <h4 class="mb-2 card-title">{{'Login' | translate}}</h4>
                <p>{{'WelcomeBackLogin' | translate}}</p>
                <ngb-alert type="light-danger" class="mb-2" *ngIf="isLoginFailed" (close)="isLoginFailed = false">
                  <p class="mb-0">Login failed!</p>
                </ngb-alert>
                <form [formGroup]="loginForm">
                  <div class="form-group">
                    <input type="text" formControlName="email" class="form-control" 
                      placeholder="{{'Email' | translate}}"
                      [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.username.invalid, 'is-valid': loginFormSubmitted && !lf.username.invalid }"
                      required>
                    <div *ngIf="loginFormSubmitted && (lf.username.invalid || lf.username.errors?.required)"
                      class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> This is
                      required</div>
                  </div>
                  <div class="form-group">
                    <input type="password" formControlName="password" class="form-control" 
                      placeholder="{{'Password' | translate}}"
                      [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.password.invalid, 'is-valid': loginFormSubmitted && !lf.password.invalid }"
                      required>
                    <div *ngIf="loginFormSubmitted && (lf.password.invalid || lf.password.errors?.required)"
                      class="help-block mt-1 text-danger"> 
                      <i class="ft-alert-circle align-middle"></i> This is required
                    </div>
                  </div>
                  <div class="d-sm-flex justify-content-between mb-3 font-small-2">
                    <a [routerLink]="['/pages/forgotpassword']">{{'Forgot Password' | translate}}?</a>
                    <a  class="btn btn-primary" (click)="onSubmit()">{{'Login' | translate}}</a>
                  </div>
                </form>
                <ngx-spinner></ngx-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
Login Page Ends


<section id="login">
  <div class="row auth-height full-height-vh m-0 d-flex align-items-center justify-content-center">
    <div class="col-4 align-items-center justify-content-center">
      <form class="form">
      <div class="card overflow-hidden">
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row m-4">
              <div class="col-lg-12 col-12 px-4 py-3">
                <h4 class="mb-2 card-title">{{'Login' | translate}}</h4>
                <p>{{'WelcomeBackLogin' | translate}}</p>
                <ngb-alert type="light-danger" class="mb-2" *ngIf="isLoginFailed" (close)="isLoginFailed = false">
                  <p class="mb-0">Login failed!</p>
                </ngb-alert>
                <form [formGroup]="loginForm">
                  <div class="form-group">
                    <label for="userinput">{{'Email' | translate}}: </label>
                    <input type="email" name="email" [(ngModel)]="email" class="form-control" 
                      placeholder="{{'EnterThe' | translate}} {{'Email' | translate}}"
                      required>

                    <input type="email" formControlName="email" class="form-control" 
                      placeholder="{{'EnterThe' | translate}} {{'Email' | translate}}"
                      [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.username.invalid, 'is-valid': loginFormSubmitted && !lf.username.invalid }"
                      required>
                    <div *ngIf="loginFormSubmitted && (lf.username.invalid || lf.username.errors?.required)"
                      class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> This is
                      required</div>
                  </div>
                  <div class="form-group">
                    <label for="userinput">{{'Password' | translate}}: </label>
                    <input type="password" name="password" [(ngModel)]="password" class="form-control" 
                      placeholder="{{'EnterThe' | translate}} {{'Password' | translate}}"
                      required> 
                   <input type="password" formControlName="password" class="form-control" 
                      placeholder="{{'EnterThe' | translate}} {{'Password' | translate}}"
                      [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.password.invalid, 'is-valid': loginFormSubmitted && !lf.password.invalid }"
                      required>                   
                    <div *ngIf="loginFormSubmitted && (lf.password.invalid || lf.password.errors?.required)"
                      class="help-block mt-1 text-danger"> 
                      <i class="ft-alert-circle align-middle"></i> This is required
                    </div>
                  </div>
                  <div class="form-group">
                    <a  class="btn btn-primary btn-block" (click)="onSubmit()">{{'Login' | translate}}</a>
                  </div>
                  <div class="form-group">
                    <div class="justify-content-between font-small-2 text-center">
                      <a (click)="onForgotPassword()">{{'Forgot Password' | translate}}?</a>
                    </div>
                  </div>
                 
                </form>
                <ngx-spinner></ngx-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>
</section>-->


<!--Login Page Starts-->
<section id="login">
  <div class="container-fluid">
    <div class="row full-height-vh m-0">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <form class="form">
          <div class="card">
            <div class="card-content">
              <div class="card-body login-img">
                <div class="row m-0">                               
                  <div class="col-lg-6 d-lg-block d-none py-2 px-3 text-center align-middle">
                    <img
                      src="../../assets/img/gallery/login.png"
                      alt=""
                      class="img-fluid mt-5"
                      width="320"
                      height="184"
                    />
                  </div>
                  <div class="col-md-6 bg-white px-4 pt-3">
                    <h4 class="card-title mb-2">{{'Login' | translate}}</h4>
                    <p class="card-text mb-3">{{'WelcomeBackLogin' | translate}}</p>
                    <label>{{'Email' | translate}}</label>
                    <input type="text" class="form-control mb-3" 
                            name="email" [(ngModel)]="email"  
                            placeholder="{{'Email' | translate}}"/>
                    <label>{{'Password' | translate}}</label>
                    <input
                      type="password" name="password"
                      class="form-control mb-3"  [(ngModel)]="password"
                      placeholder="{{'Password' | translate}}"/>
                    <div class="d-flex justify-content-between mt-2"></div>
                    <div class="fg-actions d-flex justify-content-between">
                      <div class="login-btn">
                        <a [routerLink]="['/pages/forgotpassword']"
                          class="text-decoration-none text-primary"
                          >{{'Forgot Password' | translate}}?</a> 
                      </div>
                      <div class="recover-pass">
                        <button type="button" class="btn btn-primary" (click)="onSubmit()">
                          {{'Login' | translate}}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<!--Login Page Ends-->

