
<section id="horizontal-form-layouts">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="content-header">{{'FlatManagerTitle' | translate}}</div>
            <p class="content-sub-header">{{'FlatManagerDescription' | translate}}</p>
        </div>
    </div>
    <div class="row text-left">
        <div class="col-md-12">
                <div *ngIf="viewType === 'landlord'">
                    <div class="card">
                        <div class="card-header pb-2">
                            <h4 class="card-title" id="horz-layout-colored-controls">{{'AllFlatsTitle' | translate}}</h4>
                            <p class="mb-0">{{'AllFlatsDescription' | translate}}</p>                                
                        </div>
                        <div class="card-content">
                            <div class="card-body">
                                <div class="col-md-12">
                                    <ngx-datatable class="bootstrap core-bootstrap" 
                                        [rows]="items" 
                                        [columnMode]="'force'" 
                                        [headerHeight]="50" 
                                        [footerHeight]="50"
                                        [rowHeight]="'auto'"
                                        [limit]="15">
                                        <ngx-datatable-column name="#" prop="flat.internalId"></ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Title' | translate}}" prop="flat.title">
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                <a class="primary p-0" data-original-title="" (click)="viewPage(row.flat.id)">
                                                    {{row.flat.title}}
                                                </a>
                                            </ng-template> 
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Balance' | translate}}" prop="flat.balance"  ></ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Rent' | translate}}" prop="rent"></ngx-datatable-column>
                                        <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                {{row.flat.createTime | date:'yyyy-MM-dd'}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Actions">
                                            <ng-template let-row="row"  ngx-datatable-cell-template>
                                            <a class="danger p-0" data-original-title="" title="edit" (click)="viewPage(row.flat.id)">
                                                <i class="ft-edit text-primary cursor-pointer"></i>
                                            </a>
                                            </ng-template>
                                        </ngx-datatable-column> 
                                    </ngx-datatable>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div class="card">
                        <div class="card-header pb-2">
                            <div class="row">
                                <div class="col-md-8">
                                    <h4 class="card-title" id="horz-layout-colored-controls">{{'Bills' | translate}}</h4>
                                    <p class="mb-0">{{'AllFlatsDescription' | translate}}</p> 
                                </div>
                                <div class="col-md-4">
                                    <h2 class="card-title text-right">
                                        {{'Balance' | translate}}: {{balance}} PLN
                                    </h2>
                                </div>    
                            </div>     
                        </div>
                        <div class="card-content">
                            <div class="card-body">
                                <div class="col-md-12">
                                    <ngx-datatable class="bootstrap core-bootstrap" 
                                        [rows]="invoices" 
                                        [columnMode]="'force'" 
                                        [headerHeight]="50" 
                                        [footerHeight]="50"
                                        [rowHeight]="'auto'"
                                        [limit]="15">
                                        <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                                    <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                        {{row.status}}
                                                    </span>
                                                </div>                      
                                                <ng-template #nonActiveBadge>
                                                    <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                        {{row.status}}
                                                    </span>                       
                                                </ng-template>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Title' | translate}}" prop="title"  >
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                <a class="primary p-0" data-original-title="" (click)="viewPage(row.flat.id)">
                                                    {{row.title}}
                                                </a>
                                            </ng-template> 
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Value' | translate}}" prop="value"></ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Description' | translate}}" prop="description"></ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Comment' | translate}}" prop="comment"></ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Apartment' | translate}}" >
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                <a class="primary p-0" data-original-title="" title="edit" (click)="viewPage(row.flat.id)">
                                                    {{row.flat.title}}
                                                </a>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                {{row.createTime | date:'yyyy-MM-dd'}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <!--<ngx-datatable-column name="Actions">
                                            <ng-template let-row="row"  ngx-datatable-cell-template>
                                                <a class="danger p-0" data-original-title="" title="edit" (click)="viewPage(row.flat.id)">
                                                <i class="ft-edit text-primary cursor-pointer"></i>
                                                </a>
                                            </ng-template>
                                        </ngx-datatable-column> -->
                                    </ngx-datatable>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="viewType === 'admin' || viewType === 'employee' ">
                    <div class="card">
                        <div class="card-header pb-2">
                            <h4 class="card-title" id="horz-layout-colored-controls">{{'AllFlatsTitle' | translate}}</h4>
                            <p class="mb-0">{{'AllFlatsDescription' | translate}}</p>     
                        </div>
                        <div class="card-content">
                            <div class="card-body">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <fieldset class="form-group">
                                                <label>{{'Search' | translate}}</label>
                                                <input id="ngx-filter-ref" 
                                                    class="form-control form-control-sm width-200" 
                                                    type="text"
                                                    placeholder="{{'FilterTheName' | translate}}" 
                                                    (keyup)="updateFilter($event)" />
                                            </fieldset>
                                        </div>
                                        <div class="col-md-8">
                                            <fieldset class="form-group">
                                                <label>{{'Status' | translate}}</label>
                                                <select class="form-control form-control-sm width-200" [(ngModel)]="selectedStatus" (change)="onStatusChange()">
                                                    <option value="all">{{'All' | translate}}</option>
                                                    <option *ngFor="let status of contractStatus" [value]="status">
                                                        {{status | translate}}
                                                    </option>
                                                </select>
                                            </fieldset>
                                        </div>
                                        <div class="col-md-1">
                                            <div *ngIf="viewType === 'admin'">
                                                <a class="btn btn-outline-primary" href="/flats/addflat">{{'Add' | translate}}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <ngx-datatable class="bootstrap core-bootstrap" 
                                        [rows]="items" 
                                        [columnMode]="'force'" 
                                        [headerHeight]="50" 
                                        [footerHeight]="50"
                                        [rowHeight]="'auto'" 
                                        [limit]="15"
                                        >
                                        <ngx-datatable-column name="#" prop="flat.internalId"></ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Title' | translate}}" prop="flat.title">
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                <a class="primary p-0" data-original-title="" (click)="viewPage(row.flat.id)">
                                                    {{row.flat.title}}
                                                </a>
                                            </ng-template> 
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Address' | translate}}" prop="flat.street">
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                {{row.flat.street}}, {{row.flat.building}}, {{row.flat.apartment}}
                                            </ng-template> 
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Status' | translate}}">
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                <div *ngIf="row.actualContract; else nonContract">
                                                    <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                        {{ 'RentalContractStatus.Active' | translate }}
                                                    </span>
                                                </div>
                                                <ng-template #nonContract>
                                                    <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                        {{ 'RentalContractStatus.NonActive' | translate }}
                                                    </span>  
                                                </ng-template>
                                            </ng-template> 
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Monthly' | translate}}" prop="rent"></ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Balance' | translate}}" prop="flat.balance"></ngx-datatable-column>
                                        <ngx-datatable-column name="{{'BalanceLand' | translate}}" prop="balance">
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                {{row.flat.landlord.balance}}
                                            </ng-template> 
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="{{'EndDate' | translate}}">
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                <div *ngIf="row.actualContract; else nonContractEndDate">
                                                    {{row.actualContract.endDate  | date:'yyyy-MM-dd'}}
                                                </div>
                                                <ng-template #nonContractEndDate>
                                                    -  
                                                </ng-template>   
                                            </ng-template>      
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Landlord' | translate}}">
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                <div *ngIf="row.flat.landlord; else nonLandlord">
                                                    <a class="primary p-0" data-original-title="" (click)="viewPageLandlord(row.flat.landlord.id)">
                                                        {{row.flat.landlord.firstName}} {{row.flat.landlord.lastName}}
                                                    </a>
                                                </div>
                                                <ng-template #nonLandlord>
                                                    -
                                                </ng-template>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Tenant' | translate}}">
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                <div *ngIf="row.actualContract; else nonContract">
                                                    <a class="primary p-0" data-original-title="" (click)="viewPageTenant(row.actualContract.tenant.id)">
                                                        {{row.actualContract.tenant.firstName}} {{row.actualContract.tenant.lastName}}
                                                    </a>
                                                </div>
                                                <ng-template #nonContract>
                                                    -
                                                </ng-template>
                                        
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Actions' | translate}}">
                                            <ng-template let-row="row"  ngx-datatable-cell-template>
                                            <a class="danger p-0" data-original-title="" title="edit" (click)="viewPage(row.flat.id)">
                                                <i class="ft-edit text-primary cursor-pointer mr-2"></i>
                                            </a>
                                            <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.flat.id)">
                                                <i class="ft-trash cursor-pointer mr-2"></i>
                                            </a>
                                            </ng-template>
                                        </ngx-datatable-column> 
                                    </ngx-datatable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="viewType === 'tenant'">
                    <div class="card">
                        <div class="card-header pb-2">
                            <h4 class="card-title" id="horz-layout-colored-controls">{{'AllFlatsTitle' | translate}}</h4>
                            <p class="mb-0">{{'AllFlatsDescription' | translate}}</p>     
                        </div>
                        <div class="card-content">
                            <div class="card-body">
                                <div class="col-md-12">
                                    <ngx-datatable class="bootstrap core-bootstrap" 
                                    [rows]="flats" 
                                    [columnMode]="'force'" 
                                    [headerHeight]="50" 
                                    [footerHeight]="50"
                                    [rowHeight]="'auto'"
                                    [limit]="15">
                                    <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                    <ngx-datatable-column name="{{'Title' | translate}}" prop="title">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <a class="primary p-0" data-original-title="" (click)="viewPage(row.id)">
                                                {{row.title}}
                                            </a>
                                        </ng-template> 
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="{{'City' | translate}}" prop="city"  ></ngx-datatable-column>
                                    <ngx-datatable-column name="{{'Street' | translate}}" prop="street"></ngx-datatable-column>
                                    <ngx-datatable-column name="{{'Postcode' | translate}}" prop="postcode"></ngx-datatable-column>
                                    <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Actions">
                                        <ng-template let-row="row"  ngx-datatable-cell-template>
                                        <a class="danger p-0" data-original-title="" title="edit" (click)="viewPage(row.id)">
                                            <i class="ft-edit text-primary cursor-pointer"></i>
                                        </a>
                                        </ng-template>
                                    </ngx-datatable-column> 
                                </ngx-datatable> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</section>
